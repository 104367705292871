.form-divider {
    padding-top: 15px;
    padding-bottom: 15px;

    &::before {
        width: 0 !important;
        border: none !important;
    }
    &::after {
        border-top: 1px solid #104c8f !important;
        width: 100% !important;
    }

    .MuiDivider-wrapper {
        padding-left: 0;
        font: normal normal 600 12px/24px Noto Sans !important;
        color: #104c8f !important;
    }
}

.form-header-body {
    background-color: #effaff;
    width: 100% !important;
    margin-left: 0 !important;
}

.form-header-button {
    flex-direction: column;
    align-items: center;
    text-transform: none;
    min-width: 300px;
}

.form-header-button-text {
    text-transform: none;
    white-space: nowrap;
}

.form-header-button:before {
    content: '';
    background-color: #d3d3d3;
    position: absolute;
    width: 1px;
    height: 100%;
    left: 0;
    display: block;
}

.form-header-button:after {
    content: '';
    background-color: #d3d3d3;
    position: absolute;
    width: 1px;
    height: 100%;
    left: 100%;
    display: block;
}

.form-header-button:last-child {
    content: '';
    width: 0 !important;
}

.form-header-icon {
    margin: 0 !important;
}
