.grid-container {
    display: grid;
    grid-template-columns: repeat(
        4,
        minmax(200px, 1fr)
    ); /* Adjust the column width as needed */
    gap: 10px; /* Adjust the gap between items */
}

.grid-column {
    display: flex;
    flex-direction: row;
}

.section {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    cursor: pointer;
    font-weight: 600;
    font-size: 18px;
}

.sub-section-header {
    cursor: pointer;
    padding-left: 15px;
}
